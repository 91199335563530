ul.main-nav {
    width: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
}

ul.main-nav li,
ul.main-nav li button,
ul.main-nav li button p {
    margin: 0;
    padding: 0;
}

ul.main-nav li {
    margin: .5rem 0;
}



@media screen and (min-width: 1024px) {

    ul.main-nav {
        flex-direction: row;
        justify-content: space-between;
    }

    ul.main-nav li button p {
        font-size: 1.2rem;
    }
}