#main-swiper {
    height: 100vh !important;
}


.swiper-slide {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
}

.swiper-slide>.content-text {
    height: 50%;
    max-height: 50%;
    padding: 0 1rem;
}

.swiper-slide>.swiper-horizontal {
    height: 50%;
    max-height: 50%;
    width: 100%;
}

.swiper-slide .content-text {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
}

.page-full {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

.content-home {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    padding: 2rem;
    background: #f5dbcb;
}

.footer {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    padding: 2rem;
    background: #f5dbcb;
}


#content-portfolio,
#content-reviews {
    height: 100%;
    max-height: 100%;
}


@media screen and (min-width: 1024px) {

    .swiper-slide {
        flex-direction: row;
    }


    .swiper-slide .content-text,
    .swiper-slide .content-image,
    .swiper-slide>.swiper-horizontal {
        width: 50%;
        padding: 0;
        margin: 0;
    }

    .swiper-slide .content-text {
        min-height: 100%;
        width: 40%;
        padding: 0 5%;
        background: #FCFCFC;
        -webkit-box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.2);
        z-index: 3;
    }

    .content-home {
        padding: 10rem;
    }

    #content-reviews.swiper-horizontal,
    #content-portfolio.swiper-horizontal {
        width: auto;
    }

}