.icon,
img[alt*="social-icon-"] {
    width: 100%;
    height: auto;
    object-fit: contain;
}



.social-icons {
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    margin: 0;
    padding: 0;
}

.social-icons li {
    width: 15%;
}

.contacts-icons {
    list-style: none;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    margin: 0;
    padding: 0;
}

.contacts-icons li {
    width: 15%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
}


@media screen and (min-width: 1024px) {

    .social-icons {
        width: 50%;
        margin: 4rem 0;
    }

    .social-icons li {
        width: 10%;
    }


    .contacts-icons {
        flex-direction: row;
    }

    .contacts-icons li button {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;
    }

    .contacts-icons li button img {
        width: 33%;
    }


    

}