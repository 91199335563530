:root {
    /* --polaroid-sm: 60vw;
    --polaroid-md: 42vh;
    --polaroid-lg: 52vh; */
    --polaroid-sm: 12.5rem;
    --polaroid-md: 16.5rem;
    --polaroid-lg: 20rem;
}

.polaroid {
    width: var(--polaroid-sm);
    /* height: calc(var(--polaroid-sm) + 5vw); */
    height: calc(var(--polaroid-sm) + 1rem);
    padding: 2vh;
    background: linear-gradient(115deg, rgba(238, 238, 238, 1) 0%, rgba(241, 241, 241, 1) 65%, rgba(238, 238, 238, 1) 100%);
    /*linear-gradient(60deg, rgba(220,255,253,1) 10%, rgba(255,255,255,1) 22%, rgba(241,241,230,1) 33%, rgba(244,234,239,1) 43%, rgba(255,255,255,1) 75%);*/
    border: 2px solid;
    border-image: linear-gradient(132deg, black, white) 1;
    border-top: 0;
    border-left: 0;
    font-size: .5em;
}

.polaroid>div:nth-child(1) {
    width: var(--polaroid-sm);
    height: var(--polaroid-sm);
    background: rgb(255, 255, 255);
    background: radial-gradient(circle, rgba(255, 255, 255, 1) 65%, rgba(0, 0, 0, 1) 85%);

}

.polaroid img {
    width: var(--polaroid-sm);
    height: var(--polaroid-sm);
    object-fit: cover;
    opacity: 0.9;
}

.swiper-button-prev,
    .swiper-button-next {
        color: rgba(0, 0, 0, 0.15) !important;
    }

    .swiper-button-disabled {
        animation: all ease 1s;
        opacity: 0 !important;
    }


@media screen and (min-width: 512px) {

    :root {
        --polaroid-md: 18rem;
    }

    .polaroid {
        width: var(--polaroid-md);
        /* height: calc(var(--polaroid-md) + 5vw); */
        height: calc(var(--polaroid-md) + 1rem);
    }

    .polaroid>div:nth-child(1) {
        width: var(--polaroid-md);
        height: var(--polaroid-md);

    }

    .polaroid img {
        width: var(--polaroid-md);
        height: var(--polaroid-md);
    }

}


@media screen and (min-width: 1024px) {

    :root {
        --polaroid-lg: 25rem;
    }

    .polaroid {
        width: var(--polaroid-lg);
        /* height: calc(var(--polaroid-lg) + 5vw); */
        height: calc(var(--polaroid-lg) + 1rem);
    }

    .polaroid>div:nth-child(1) {
        width: var(--polaroid-lg);
        height: var(--polaroid-lg);

    }

    .polaroid img {
        width: var(--polaroid-lg);
        height: var(--polaroid-lg);
    }

}




















/* 
    transform: translate(-40%, 0%) rotate(-2deg); 
    transform: translate(0%, 0%) rotate(0deg);
  */


/* .swiper-wrapper .swiper-slide-active .polaroid {
    animation: all ease 3s;
    transform: translate(-0em, 0) rotate(-0deg);
  }

  .swiper-wrapper .swiper-slide-next .polaroid {
    animation: all ease 3s;
    transform: translate(-30em, 0) rotate(-2deg);
  } */