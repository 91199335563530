.review-item {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
}

.review-item .review-image {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

.review-item .review-body {
    padding: 0 1rem;
}

.review-item .review-body h2 {
    text-align: center;
}


@media screen and (min-width: 750px) {

    .review-item .review-body {
        padding: 0 12.5rem;
    }
}

@media screen and (min-width: 1024px) {

    .review-item {
        flex-direction: row;
    }

    .review-item .review-image {
        width: 50%;
    }

    .review-item .review-body {
        width: 40%;
        padding: 0;
        padding-right: 10%;
    }
}