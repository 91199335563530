@import url('https://fonts.googleapis.com/css2?family=Allura&family=Lora:ital,wght@0,400..700;1,400..700&family=Oooh+Baby&family=Zeyada&display=swap');

@font-face {
    font-family: 'Antic-Slab';
    src: local('AnticSlab-Regular'), url(./resources/fonts/AnticSlab-Regular.ttf) format('truetype');
}



h1, h2, h3, p {
    margin: 0;
    padding: 0;
}

h1 {
    font-size: 2.2rem;
    line-height: 2rem;
    margin: .5rem 0;
    font-family: Antic-Slab, serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: 500;
}

h2 {
    font-size: 1.8rem;
    line-height: 1.6rem;
    margin-bottom: 1rem;
    font-family: Oooh Baby, cursive;
    font-style: normal;
    font-weight: 400;
}

h3 {
    font-size: 1.4rem;
    line-height: 1.4rem;
    margin: 1rem 0;
    font-family: Oooh Baby, cursive;
    font-style: normal;
    font-weight: 400;
}

p {
    font-size: 1.2rem;
    line-height: 1rem;
    font-family: Lora, serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: 500;
}

button {
    border: 0;
    background: transparent;
}



#image-logo,
#footer-image-logo {
    height: auto;
    width: 100%;
    object-fit: contain;
}

#footer-logo {
    height: auto;
    width: 50%;
    object-fit: contain;
    margin-bottom: 1rem;
}


#footer-top {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

#footer-bottom {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

.contacts-detail {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}


@media screen and (min-width: 1024px) {

    h1 {
        font-size: 3rem;
        line-height: 3rem;
    }
    
    h2 {
        font-size: 2rem;
        line-height: 2rem;
    }
    
    h3 {
        font-size: 1.5rem;
        line-height: 1.5rem;
    }
    
    p {
        font-size: 1.1rem;
        line-height: 1.1rem;
    }


    #image-logo {
        width: 65%;
    }

    #footer-logo {
        width: 20%;
    }

    #footer-image-logo {
        width: 40%;
    }

}